@tailwind base;
@tailwind components;
@tailwind utilities;



.bg-theme-cart {
  @apply  text-black shadow-sm shadow-gray-900;

} 
.blur-effect-theme {
  @apply bg-white/50 bg-clip-padding backdrop-filter backdrop-blur bg-opacity-50 opacity-100 z-[100] shadow-sm shadow-slate-100 transition-all duration-300;
}

.icon-style {
  @apply h-6 w-6 sm:h-5 sm:w-5 text-slate-100 cursor-pointer transition-all duration-100 active:scale-110 leading-tight;
}

.text-gradient {
  @apply text-transparent bg-clip-text bg-gradient-to-tr from-blue-500 to-sky-500;
}

.rounded-theme {
  border-radius: 5% 100% 10% 5%;
}

.button-theme {
  @apply px-7 py-2 rounded active:scale-90 transition-all duration-100 ease-in-out shadow-md sm:text-sm;
}
.transitions-theme {
  @apply transition-all duration-700 ease-in-out cursor-pointer;
}

.commonSearch {
  background: #fbf3f6;
  border-radius: 50px;
  border: 0;
}

.commonSearch.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-radius: 50px;
  border: 0;
  background-color: transparent;
}

.commonSearch.ant-input-search .ant-input-search-button {
  border: 0;
}

.commonSearch.ant-input-search .ant-input-search-button .ant-btn-icon {
  color: #614312;
}
