@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500&display=swap');


* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}


/* 
body {
  font-family: 'Poppins', sans-serif;
  background-color:#FBF3E6;
  font-size: 16px;
  color: var(--textBold);
  margin: 0 auto;
  overflow-x: hidden !important;
  max-height: 100vh;
  scroll-behavior: smooth;
  box-sizing: border-box;
} */

:where(.css-dev-only-do-not-override-2i2tap).ant-drawer .ant-drawer-header {
  display: none;
  flex: 0;
  align-items: center;
  padding: 16px 24px;
  font-size: 16px;
  line-height: 1.5;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
  /* background-color: black; */
}


.slick-dots li button:before {
  display:none;
}
.custom-arrow {
  font-size: 35px;
  width: 40px;
  height: 40px;
  /* background-color: #f0f3f7; */
  color: rgb(50, 66, 50);
  border: none;
  cursor: pointer;
  border-radius: 50%;
  outline: none;
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  z-index: 1;
  /* Set a higher z-index to ensure the arrow is above the cards */
}

.custom-arrow.prev {
  left: 5px;
}

.custom-arrow.next {
  right: 5px;
}
/* HProduct.css */

.slick-dots {
  display: none !important;
}

.disable{
  display: none;
}

.popUp-wrapper,
.loginPopUp-wrapper {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1001;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0 ,0 , 0.5);
}

.popUp-content-wrapper,
.loginPopUp-content-wrapper {
  background-color: #fff;
  color: #111;
  width: 90%;
  /* Adjust width to fit within the screen */
  max-width: 500px;
  /* Limit maximum width for larger screens */
  height: 90%;
  /* Adjust height to fit within the screen */
  max-height: 500px;
  /* Limit maximum height for larger screens */
  border-radius: 7px;
  position: fixed;
  /* Use fixed positioning to keep the popup within the viewport */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* Media query for mobile devices */
@media only screen and (max-width: 767px) {

  .popUp-content-wrapper,
  .loginPopUp-content-wrapper {
    width: 95%;
    /* Adjust width for smaller screens */
    max-width: none;
    /* Remove the maximum width constraint for smaller screens */
    height: 50%;
    /* Adjust height for smaller screens */
    max-height: none;
    /* Remove the maximum height constraint for smaller screens */
  }
}

.popUp-content,
.loginPopUp-content {
  padding: 3rem;
}

.popUp-content h1,
.loginPopUp-content h1 {
  font-size: 2.75rem;
  font-weight: 600;
}

.ant-picker-ok .ant-btn{
  background-color: theme('colors.primaryColor');
  color: theme('colors.textWhite');
}

/* Media query for mobile devices (max-width: 767px) */
@media only screen and (max-width: 767px) {

  .popUp-content h1,
  .loginPopUp-content h1 {
    font-size: 2rem;
    /* Adjust the font size for mobile devices */
  }
}

.popUp-content p,
.loginPopUp-content p {
  color: dodgerblue;
  font-size: 20px;
  margin-top: 10px;
}

@media only screen and (max-width: 767px) {

  .popUp-content p,
  .loginPopUp-content p {
    font-size: 16px;
    /* Adjust the font size for mobile devices */
  }
}

.popUp-content input,
.loginPopUp-content input {
  border: none;
  outline: none;
  border-bottom: 2px solid lightgreen;
  padding: 0.5rem 0;
  margin-top: 10px;
}

.popUp-content button,
.loginPopUp-content button{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(255,165,0);
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.popUp-content .right-arrow-icon,
.loginPopUp-content .right-arrow-icon {
  font-size: 2.5rem;
  font-weight: 400;
  color: #fff;

}

.popUp-content .info,
.loginPopUp-content .info{
  margin-top: 1rem;
  color: grey;
}

.admin-prodList th{
  padding: 1rem 0;
}

.admin-prodList td {
  padding: 0.45rem 0;
}

.imgArea{
  background: url(./Assests/images/Npim.png);
  height: 300px;
}

.card:hover{
  scale: 1.08;
}

.cartWrapper{
  height: var(100vh-364px);
}


.btn {
  border: 1px solid;
  border-image: linear-gradient(90deg, #874F00 -0.06%, #9D7201 28.38%, #9D7201 54.41%, #7E4400 100.64%) 1;

}

.primaryBtn{
  background-color: #AF9550;
  color: #fff;
  border-radius: 5px;
  height: 50px;

}
.ant-breadcrumb a {
  color: green;
}
.ant-breadcrumb-link {
  color:#111;
}
:where(.css-dev-only-do-not-override-1qhpsh8) a:hover {
  color:#EE7214;
}
:where(.css-dev-only-do-not-override-1qhpsh8).ant-drawer .ant-drawer-body {
  background-color: rgba(239, 233, 220, 1);
}

:where(.css-dev-only-do-not-override-1qhpsh8).ant-drawer .ant-drawer-header {
  background-color: #5E3300;
}

.drawerclose .anticon svg {
  color: antiquewhite;
}

.slick-prev,
.slick-next {
  margin-left: -30px;
  margin-right: -21px;
  height: 50px !important; 
  width: 50px !important;
}

:where(.css-dev-only-do-not-override-1qhpsh8).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
  border-color: white;
}

:where(.css-dev-only-do-not-override-1qhpsh8).ant-select-multiple .ant-select-selection-placeholder {
      
      inset-inline-end: 93%;
} 

#spcial-scroll .ant-table-body::-webkit-scrollbar-track,
#spcial-scroll .ant-table-content::-webkit-scrollbar-track,
#unquie-scroll::-webkit-scrollbar-track,
:where(.css-dev-only-do-not-override-1oi1tey).ant-picker-calendar.ant-picker-calendar-full .ant-picker-calendar-date-content::-webkit-scrollbar-track,
.patient-tab:where(.css-dev-only-do-not-override-1oi1tey).ant-tabs .ant-tabs-content-holder::-webkit-scrollbar-track,
.ant-table-body::-webkit-scrollbar-track,
.message-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(159, 159, 159, 0.3);
  border-radius: 10px;
  background-color: rgb(238, 238, 238);
}

#spcial-scroll .ant-table-body::-webkit-scrollbar,
#spcial-scroll .ant-table-content::-webkit-scrollbar,
#unquie-scroll::-webkit-scrollbar,
.patient-tab:where(.css-dev-only-do-not-override-1oi1tey).ant-tabs .ant-tabs-content-holder::-webkit-scrollbar,
.ant-table-body::-webkit-scrollbar,
.message-list::-webkit-scrollbar {
  width: 8px;
  height: 10px;
  background-color: #f5f5f5;
}

#spcial-scroll .ant-table-body::-webkit-scrollbar-thumb,
#spcial-scroll .ant-table-content::-webkit-scrollbar-thumb,
#unquie-scroll::-webkit-scrollbar-thumb,
:where(.css-dev-only-do-not-override-1oi1tey).ant-picker-calendar.ant-picker-calendar-full .ant-picker-calendar-date-content::-webkit-scrollbar-thumb,
.patient-tab:where(.css-dev-only-do-not-override-1oi1tey).ant-tabs .ant-tabs-content-holder::-webkit-scrollbar-thumb,
.ant-table-body::-webkit-scrollbar-thumb,
.message-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #eaeaea;
}
